<template>
  <div>
    <div class="text-center mb-6">Ha Ocurrido un Error</div>
    <v-row justify="center">
      <v-img :src="require(`@/assets/img/Error-payment.svg`)" contain max-height="100" max-width="150" />
    </v-row>
    <div class="text-center roboto text-subtitle-1 mt-6">Antes de volver a intentar, por favor verifica tu cuenta.</div>
    <v-row justify="center" class="mt-5">
      <v-col cols="12" order="2" order-md="1" xs="12" sm="12" md="2" lg="2" xl="2">
        <v-btn width="100%" class="tway-violet--text mr-4 roboto" large color="white" text :to="{ name: 'plans' }">
          VER PLANES
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.title-profile {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}
.text-profile {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}
</style>
<script>
import subscriptionService from "@/services/subscriptionService";

export default {
  name: "ErrorPayment",

  data: () => ({}),

  created() {
    this.getSubscription();
  },

  methods: {
    getSubscription: function () {
      subscriptionService
        .getCurrentUserSubscription()
        .then((subscription) => (this.subscription = subscription))
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .then(() => (this.isLoading = false));
    },
  },
};
</script>
